<!--
* @description:
* @fileName edit-bannar.vue
* @author hvv
* @date 2022/01/12 09:19:18
!-->
<template></template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  export default defineComponent({
    name: 'edit-bannar',
    setup() {},
  })
</script>
<style lang="scss" scoped></style>
